@import url(https://unpkg.com/tailwindcss@^2/dist/tailwind.min.css);
@import url(https://fonts.googleapis.com/css2?family=Inter&display=swap);
.cursive {
	font-family: 'Inter';
	font-weight: 700;
}

.prose img {
	margin-left: auto;
	margin-right: auto;
	border-radius: 5px;
}

.home-name {
	font-size: 150px;
}

